<template>
  <b-modal
    v-model="showModal"
    centered
    title="Delete Train Batch"
    @hidden="$emit('modal-closed')"
    @ok="confirmHandler"
  >
    <b-card-text>
      <div v-if="ids.length === 1">
        Are you sure you want to delete the train batch <span class="text-primary">{{ ids[0] }}</span>?
      </div>
      <div v-else>
        Are you sure you want to delete the following train batches?
        <div
          v-for="(id, index) of ids"
          :key="index"
          class="text-primary"
        >
          {{ id }}
        </div>
      </div>

    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="danger"
        :disabled="isDeleting"
        @click="ok()"
      >
        Delete
        <b-spinner
          v-if="isDeleting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      isDeleting: false,
    }
  },
  methods: {
    confirmHandler(event) {
      event.preventDefault()

      this.isDeleting = true

      axios.delete('/train-batches/delete_multiple/', {
        data: {
          ids: this.ids,
        },
      })
        .then(() => {
          this.$emit('deleted')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.ids.length === 1 ? 'Train Batch deleted sucessfully' : 'Train Batches deleted sucessfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.showModal = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Somthing went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.isDeleting = false
        })
    },
  },
}

</script>
