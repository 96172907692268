<template>
  <div>
    <h2>Training</h2>
    <!-- <Batches :training-mode="true" /> -->
    <TrainBatches :training-mode="true" />
  </div>
</template>

<script>
// import Batches from '@/components/Batches/Batches.vue'
import TrainBatches from '@/components/Batches/TrainBatches.vue'

export default {
  components: {
    // Batches,
    TrainBatches,
  },
}
</script>
